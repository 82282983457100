import ApiService from "@/core/services/ApiService";
type Nullable<T> = T | undefined | null;
interface IStorePromotion {
  id?: Nullable<number>;
  code: Nullable<Nullable<string>>;
  name: Nullable<string>;
  packageId: Nullable<number>;
  packages?: any;
  numberMonth: Nullable<number>;
  countVoucher: Nullable<number>;
  discountRate: Nullable<number>;
  discountPrice: Nullable<number>;
  isMain: Nullable<boolean>;
  promotionId: Nullable<number>;
  startDate: any;
  endDate: any;
  state: Nullable<string>;
  createdAt?: Nullable<number>;
  updatedAt?: Nullable<number>;
}

function getPromotions(
  page = 0,
  size = 10,
  isMain = false,
  name = "",
  package_id = null,
  state = null
) {
  const params = {
    isMain: isMain,
    name: name,
    package_id: package_id,
    state: state,
    page: page,
    size: size,
  };
  ApiService.setHeader();
  return ApiService.query("/v1/package/promotion", { params });
}

function getPromotion(id) {
  ApiService.setHeader();
  return ApiService.get(`/v1/package/promotion/${id}`);
}

function createPromotion(data) {
  ApiService.setHeader();
  return ApiService.post(`/v1/package/promotion`, data);
}

function updatePromotion(id, data) {
  ApiService.setHeader();
  return ApiService.put(`/v1/package/promotion/${id}`, data);
}

function getPackages() {
  const params = {};
  ApiService.setHeader();
  return ApiService.query("/v1/store/package_list", params);
}

function addVoucher(idPromotion, countVoucher) {
  ApiService.setHeader();
  const params = {
    countVoucher: countVoucher,
  };
  return ApiService.post(`/v1/package/promotion/${idPromotion}/voucher`, {
    params: params,
  });
}

export {
  IStorePromotion,
  createPromotion,
  addVoucher,
  getPromotions,
  updatePromotion,
  getPromotion,
  getPackages,
};
