
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import NewCardModal from "@/components/modals/forms/NewCardModal.vue";
import Vouchers from "./Vouchers.vue";
import { getPromotion, IStorePromotion } from "@/core/data/StorePromotion";
import moment from "moment";

export default defineComponent({
  name: "store-promotion-vouchers",
  components: {
    // Dropdown3,
    NewCardModal,
    Vouchers,
  },
  setup() {
    const promotionData = ref({});
    const promotionId = ref();
    const state = ref();
    const route = useRoute();
    const handleClickTabItem = (value) => {
      state.value = value;
    };

    onMounted(() => {
      promotionId.value = route.params.promotionId;

      getPromotion(promotionId.value)
        .then((response) => {
          promotionData.value = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
      setCurrentPageBreadcrumbs("Chi tiết khuyến mãi", [
        "Apps",
        "Chương trình marketing",
      ]);
    });

    return {
      promotionData,
      promotionId,
      state,
      handleClickTabItem,
    };
  },
  methods: {
    getState(startDate, endDate) {
      let now = new Date().getTime();
      if (now >= endDate) return "EXPIRED";
      else {
        let diff = startDate - now;
        if (diff <= 0) return "HAPPENING";
        else if (diff <= 259200 && diff >= 0) return "UPCOMING";
        return "REGISTERED";
      }
    },
    convertIntToDateTime(value) {
      let timestamp = value / 1000;
      return moment.unix(timestamp).format("DD-MM-YYYY HH:mm:ss");
    },
    // convertIntToDateTime(value) {
    //   return value[2] + '-' + value[1] + '-' + value[0]
    // },
  },
});
